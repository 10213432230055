<template>
  <div class="InvoiceDetail-view">
    <v-card flat v-if="isLoading">
      <v-card-text>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card flat>
        <v-container fluid>
          <table class="center">
            <tbody class="report-container">
              <v-card-text>
                <v-data-table dense readonly :sort-by="sortBy" :headers="headers" :itemsPerPage="InvoiceDetail.length"
                  hide-default-footer :items="InvoiceDetail">
                  <template v-slot:item="{ item }">
                    <tr v-if="item.totalPrice - item.totalCost !== 0">
                      <td class="text-left">
                        <a href="#" @click="viewInvoice(item.InvoiceId)">{{
                            item.Invoice.localId
                        }}</a>
                      </td>
                      <td class="text-left">
                        <div>{{ item.Invoice.date | moment("MM/DD/YYYY") }}</div>
                        <div v-if="item.imeiNumber">{{ item.imeiNumber }}</div>
                      </td>
                      <td class="text-left">
                        <div v-if="item.Invoice.Customer">{{ item.Invoice.Customer.name }}</div>
                        <div class="caption">
                          <span v-if="item.Invoice.Customer.phoneNumber">
                            <v-chip small>
                              <v-icon small>mdi-phone</v-icon>
                              {{ item.Invoice.Customer.phoneNumber }}
                            </v-chip>
                          </span>
                          <span v-if="item.Invoice.Customer.cellNumber">
                            <v-chip small color="yellow lighten-4">
                              <v-icon small>mdi-cellphone-basic</v-icon>
                              {{ item.Invoice.Customer.cellNumber }}
                            </v-chip>
                          </span>
                          <!-- <span v-if="item.Invoice.Customer.cellNumber">{{
                            item.Invoice.Customer.cellNumber
                          }}</span> -->
                        </div>
                      </td>


                      <td class="text-center">
                        {{ item.qty }}
                      </td>
                      <td class="text-right">
                        {{ item.totalSale | currency }}
                      </td>
                      <td v-if="!hideCost" class="text-right">
                        {{ item.totalCost | currency }}
                      </td>
                      <td v-if="!hideProfit" class="text-right" :style="{ color: color(item) }">
                        {{ (item.totalSale - item.totalCost) | currency }}
                      </td>
                      <td >
                        <span v-if="item.Invoice.User">{{ item.Invoice.User.name }}</span>
                        <span v-else>N/A</span>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </tbody>
          </table>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import invoiceDetail from "@/modules/Invoice/service.js";
import Hashids from "hashids";
const hashids = new Hashids();

export default {
  props: ["ProductId", "CustomerId", "hideProfit", "hideCost", "start", "end"],
  data() {
    return {
      isLoading: true,
      InvoiceDetail: [],
      sortBy: "InvoiceId",
      headers: [
        {
          text: "INVOICE NUMBER",
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: "CUSTOMER NAME",
          align: "left",
          value: "Invoice.Customer.name",
          sortable: true,
        },
        {
          text: "DATE",
          align: "left",
          value: "Invoice.date",
          sortable: true,
        },
        {
          text: "QTY",
          align: "center",
          value: "qty",
          sortable: true,
        },
        {
          text: "PRICE",
          align: "right",
          value: "totalsale",
          sortable: true,
        },
        // {
        //   text: "COST",
        //   align: "right",
        //   value: "totalCost",
        //   sortable: true,
        // },
        // {
        //   text: "PROFIT",
        //   align: "right",
        //   value: "profit",
        //   sortable: true,
        // },
        // {
        //   text: "USER",
        //   align: "left",
        //   value: "Invoice.User.name",
        //   sortable: true,
        // },
      ],
    };
  },
  computed: {
    totalProfit() {
      return this.totalPrice - this.totalCost;
    },
    totalPrice() {
      let total = 0;
      this.InvoiceDetail.map((row) => {
        total += row.totalPrice;
      });
      return total;
    },
    totalCost() {
      let total = 0;
      this.InvoiceDetail.map((row) => {
        total += row.totalCost;
      });
      return total;
    },
  },
  watch: {
    CustomerId() {
      this.getInvoiceDetail();
    },
  },
  async created() {
    // this.start = this.$route.query.start;
    // this.end = this.$route.query.end;
    await this.getInvoiceDetail();
    if (!this.hideCost) {
      this.headers.push({
        text: "COST",
        align: "right",
        value: "totalCost",
        sortable: true,
      });
    }
    if (!this.hideProfit) {
      this.headers.push({
        text: "PROFIT",
        align: "right",
        value: "totalProfit",
        sortable: true,
      });
    }
    this.headers.push({
      text: "USER",
      align: "left",
      value: "Invoice.User.name",
      sortable: true,
    });
  },
  methods: {
    viewInvoice(invoice) {
      const hashId = hashids.encode(invoice);
      let routeData = null;
      if (this.$route.meta.public) {
        routeData = this.$router.resolve({
          path: `/public/invoice/${hashId}/view`,
        });
      } else {
        routeData = this.$router.resolve({
          path: `/global/invoice/${hashId}/view`,
        });
      }

      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    color(item) {
      let value = "black";
      let profit = item.totalSale - item.totalCost;
      if (profit < 0) {
        value = "red";
      }
      return value;
    },
    getInvoiceDetail() {
      let where = {};
      if (this.start) where.start = this.start;
      if (this.end) where.end = this.end;
      if (this.CustomerId) where.CustomerId = this.CustomerId;
      if (this.ProductId) where.ProductId = this.ProductId;

      // let where = {
      //   start: this.start,
      //   end: this.end
      // };

      // if (this.ProductId) {
      //   where.ProductId = this.ProductId
      // }

      return invoiceDetail.getAllInvoiceDetail(where).then((response) => {
        // console.log("response InvoiceDetail", response);
        this.$Progress.finish();
        this.InvoiceDetail = response.data;
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped>
@media print {
  container {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  .no-print {
    display: none;
  }

  .detailBox {
    overflow: visible;
    height: 100%;
  }

  * {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}

.paidImg {
  position: absolute;
  left: 40%;
  top: 10%;
}

.signature {
  margin-top: 100px;
}

.center {
  background-color: white;
  margin: auto;
  width: 100%;
}

/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>
